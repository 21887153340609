<template lang="pug">
  .main-content.layout-main__centering.container.pt-3
    .page
      .terms_of_use
        h1(style='text-align: center;')
          strong Terms of Service
        p  
        h3
          strong Welcome to Finex.market
        p
          span(style='font-weight: 400;')
            | Finex is the cryptocurrency P2P trading platform which allows users to buy, sell and store a wide range of cryptocurrencies. Buying and selling cryptocurrencies works through the custom listing which is posted by sellers or buyers. Other users who are interested in a particular listing should agree on a cryptocurrency trade in order to receive the desired asset.
        p  
        p
          span(style='font-weight: 400;')
            | If the user wants to sell cryptocurrency he should store it on the platform, so we can ensure that trades will be transparent and legit. All trades are performed solely by users, and Finex provides a platform where people can find each other and complete a desired financial operation.
        p  
        p
          span(style='font-weight: 400;')
            | Moreover, we are offering the ESCROW and orbiter services which can help to resolve any disputes which can occur during the trading process.Thereby, Finex has the right to help users in different disputes, resolving subject-matter of the dispute fairly in any circumstances.
        p
          span(style='font-weight: 400;')
            | The following Terms and Conditions («Agreement») form a legal agreement, which covers provision of services («service» or «services»), offered by Finex («Finex.market» or «Finex», or «us», or «our», or «we», “service”, “platform”) for you as the user («you» or «your»). Usage of our services will be working in compliance with this Agreement and Privacy Policy of www.Finex.market. Please, carefully read and study this Agreement and Privacy Policy before accepting it.
        p  
        h3
          strong
        h3
          strong Acceptance and Change of Terms
        p
          span(style='font-weight: 400;')
            | Before you can register an account you must accept our Terms of Service and Privacy Policy. By accepting this Agreement or by using the Service, you expressly acknowledge and agree that you are entering into a legal agreement with Finex, and have understood and agree to comply with, and be legally bound by, this Agreement.
        p
          span(style='font-weight: 400;')
            | For changes to these Terms of Service that will significantly affect your rights and obligations we will strive to notify you before the changes take effect. You can terminate this Agreement at any time by concluding any outstanding trades and other obligations, withdrawing any remaining balances and deleting your account.
        h3
          strong Registration and User Account
        p
          span(style='font-weight: 400;')
            | In order to start using our service, everyone has to go through the registration process, providing the necessary information needed for account creation («User Account» or «Account») which is mandatory for service access and usage. Finex has the right to request additional information from the user during the platform usage, otherwise, Finex can stop the service provision, freezing or banning the user’s account.
        p  
        p
          span(style='font-weight: 400;')
            | If we consider that your information is false, irrelevant, confusing or not full and it doesn’t correspond with our policies, we have the right to revoke access to the website or any of its resources, as well as to ban or suspend your account.
        p  
        p
          span(style='font-weight: 400;')
            | You may only use your own account at Finex.market and you may only have one account registered. You may only act on your own behalf. You may not use your account to act as an intermediary or broker for any person or entity. You are not allowed to sell, borrow, share or otherwise make available your account or any detail necessary to access your account to people or entities other than yourself.
        p  
        p
          span(style='font-weight: 400;')
            | Registering an account you confirm, that you are older the age of 18, and you won’t be using an account to conduct criminal or fraudulent activities, money laundering and terrorism financing, Otherwise, if we find any suspicious activity we have the right to ban or suspend your user account. You are responsible for maintaining adequate security and control of your username, password, a two-factor authentication code or any other codes or credentials that you use to access services.
        p  
        p
          span(style='font-weight: 400;')
            | Your account must not contain misleading or fraudulent information, including, but not limited to having a non-personal phone number. Creating fake reputation information for your account, faking your country of origin or providing fraudulent identification documents is prohibited.
        h3
          strong Identity Verification
        p
          span(style='font-weight: 400;')
            | In order to ensure Finex remains a safe platform for all of our customers we may require you to verify your identity to access part of the services we offer. Identity verification may be required when you create advertisements, pass certain trade volume limits, during trade disputes, fraud investigations, and to ensure account ownership. As part of our ID verification process we require you to provide us with your full name as well as documents and photographs that verify your identity.
        p
          span(style='font-weight: 400;')
            | In certain situations we may require enhanced identity verification. This may include requirements to verify details or sources of funds regarding payments you have made or received during trades on Finex as well as cryptocurrencies transactions that you’ve sent or received from your Finex account.
        p
          span(style='font-weight: 400;')
            | ID verified accounts have a clear owner which makes it easy to return access to you if you lose all access to your account. It is very hard or impossible to return access to accounts that are unverified, even if you know your username, as it is difficult to identify the original account owner.
        h3
          strong Cryptocurrency Trading
        p
          span(style='font-weight: 400;')
            | You can initiate a cryptocurrency trade («trading» or «contact»), buying or selling the desired asset through available listings, which were created by other users.
        p  
        p
          span(style='font-weight: 400;')
            | If you send a trading request, you automatically agree on the conditions of this trade, which were set by the listing owner (or trader)  (the owner can choose available trading conditions once the listing is being created, or describe custom conditions written in the free form on the listing page). Terms and conditions outlined by a seller are valid in all circumstances, except for cases when they contradict or break this Terms and Conditions, are unreasonable or otherwise challenging to comply with, are illegal or if both sides decide to change trading conditions, using private messages or trading chat on the platform.
        p
          span(style='font-weight: 400;')
            | Exchange rate, amount of cryptocurrency, payment initiation duration, payment options and other trading conditions which can be found on the listing page before sending a trading request can’t be changed after if you already submitted a trading request.
        p
          span(style='font-weight: 400;')
            | If you sell cryptocurrency you are obligated to check and confirm that you have received full payment regardless of the buyer’s payment proof, you have also to confirm that a third party didn't make the payment, and the whole trading process was legit in respect with your instructions given to a buyer. If you have initiated the trade with a buyer it is not possible to stop, reverse, dispute, restore or return your cryptocurrency.
        p
          span(style='font-weight: 400;')
            | All payments made by the buyers have to be sent by the same party, who has registered and used the account(or by themselves). Payment details provided by sellers have to belong to the owners of Finex user accounts(or by themselves). It’s restricted to send or receive payments from or to third parties, any attempt to initiate this type of the payment is breaking this agreement.
        p
          span(style='font-weight: 400;')
            | All communication related to the trade should be conducted solely in trading chat, so it could be available for view if Finex customer service needs to intervene and resolve a dispute between a seller and a buyer. Messages, which were not sent on the Finex platform, or which were encrypted, obfuscated to hide something in trading activities, won’t be considered in dispute resolution.
        h3
          strong Disputing Cryptocurrency Trades
        p
          span(style='font-weight: 400;')
            | When a buyer or seller disagree on a trade, every trade participant can open a dispute, including Finex («Controversial trade» or «Dispute»). The platform intervenes into these conflict situations in order to play a third party role which initiates a fair trading process on deposited cryptocurrency. Controversial trades are reviewed and resolved solely by Finex customer service.
        p
          span(style='font-weight: 400;')
            | Disputes can be initiated only on the trades, which were marked as paid by a buyer. Trades, which were not paid by a buyer, canceled by a buyer, automatically canceled or resolved are considered as completed and can’t be disputed, canceled or changed, except for situations when Finex can reconsider the final decision.
        p  
          span(style='font-weight: 400;')
            | When you are participating in cryptocurrency trading, it’s important to stay active and available from the initiation moment until the completion, cancellation or resolution of the trade. It means that you have to give a reply to Finex customer service within 12 hours if there’s a subject-matter of the dispute, otherwise, if you refuse to cooperate with Finex customer service team, then there’s a higher chance that the final decision will be made against you. 
        h3
          strong Dispute Review
        p
          span(style='font-weight: 400;')
            | Finex customer service will provide you a set of instructions that you have to follow during the dispute review. These instructions may force you to provide the proof of payment, or the evidence that you have or haven’t received a payment( for example, transaction history from your bank account, additional identity verification, photo, video, audio files that Finex can request, considering them appropriate. Besides the evidence described above, Finex has the right to request any other information or documents that would help to resolve the dispute fairly. Failure to follow the instructions may result in a negative decision against you.
        p
          span(style='font-weight: 400;')
            | Controversial trading situations are fairly resolved by Finex, transferring cryptocurrency from seller’s to buyer’s account, if one of the dispute resolution requirements are met.
        p
          span(style='font-weight: 400;')
            | In rare situations, when none of the parties meet specific dispute requirements, or the situation is not apparent, confusing, and it is not possible to understand which side has reached the dispute requirements, only then Finex can make a final decision based on its judgments, dividing cryptocurrency equally or unequally between buyer and seller.
        h3
          strong Dispute resolution criteria
        p
          span(style='font-weight: 400;')
            | Finex can resolve a disputed trade to a cryptocurrencies buyer when one of the following criteria are met:
        ul
          li(style='font-weight: 400;')
            span(style='font-weight: 400;')
              | The buyer has made payment according to the instructions provided by the seller in the trade chat, advertisement terms of trade, or advertisement payment details and the buyer has provided sufficient proof that the payment was made according to these instructions.
          li(style='font-weight: 400;')
            span(style='font-weight: 400;') The seller has become unresponsive.
        p
          span(style='font-weight: 400;')
            | Finex can resolve a disputed trade to a cryptocurrencies seller when one of the following criteria are met:
        ul
          li(style='font-weight: 400;')
            span(style='font-weight: 400;') The buyer has not provided payment or not provided payment in full
          li(style='font-weight: 400;')
            span(style='font-weight: 400;') The buyer has become unresponsive
          li(style='font-weight: 400;')
            span(style='font-weight: 400;')
              | The payment made by the buyer has been held/frozen/stopped by the payment provider
          li(style='font-weight: 400;')
            span(style='font-weight: 400;')
              | The buyer has not provided payment according to the instructions provided to them by the seller in the trade chat, advertisement terms of trade or advertisement payment details.
          li(style='font-weight: 400;')
            span(style='font-weight: 400;')
              | The payment is made by a third party to the trade OR the payment is made from a payment account not registered in the name of the buyer.
        p
          span(style='font-weight: 400;')
            | If the buyer or the seller of a disputed trade provides fraudulent information or fraudulent documents or makes false claims or otherwise tries to force a certain outcome of a disputed trade the dispute may be immediately resolved against the user.
        h3
          strong Incorrect Dispute Resolution
        p
          span(style='font-weight: 400;')
            | If you believe Finex has resolved a dispute you are a party of in a way which is not in accordance with these terms you have a right to request a review. To request a review you need to notify us and specifically request a review by contacting customer support no later than 120 days after the dispute resolution.
        h3
          strong Advertisements
        p
          span(style='font-weight: 400;') You can create cryptocurrency trade advertisements ("
          strong advertisement
          span(style='font-weight: 400;') "" or "
          strong ad
          span(style='font-weight: 400;') " or "
          strong ads
          span(style='font-weight: 400;')
            | ") to advertise that you want to buy or sell cryptocurrencies . Other users can send trade requests to you from the advertisements that you’ve created. When a trade request is sent to you a cryptocurrency trade is automatically started.
        p
          span(style='font-weight: 400;')
            | When you have advertisements enabled you are required to be active and available to reply to trades started from these advertisements. If a dispute is initiated in a trade against an advertiser who is not active and available the advertiser may be deemed unresponsive as per this agreement and the dispute may be resolved against them.
        p
          span(style='font-weight: 400;')
            | Information and payment details you provide in advertisements and trades must at all times be correct and up to date. Disputes may be resolved against you if you include out-of-date payment details and a buyer completes payment accordingly. Refusal to complete trades or to provide payment details for trades opened from your advertisements is considered a violation of this agreement.
        p
          span(style='font-weight: 400;')
            | The price or price equation that is defined in the advertisement details must contain any and all fees that the advertiser requires the buyer to pay. After initiation of a trade the price is locked and cannot be changed or any additional fees added without consent from both parties of the trade.
        p  
        h3
          strong Online advertisements
        p
          span(style='font-weight: 400;')
            | Online advertisements are advertisements in the categories "Buy cryptocurrencies online" and "Sell cryptocurrencies online" or any other advertisement where the payment method is any other than cash or the advertisement type is online buy or online sell.
        ul
          li(style='font-weight: 400;')
            span(style='font-weight: 400;') You must place your ads in the correct payment category.
          li(style='font-weight: 400;')
            span(style='font-weight: 400;') Multiple ads for the same payment method are not allowed in the same country.
        h3
          strong Limitation, Suspension, and Termination of Your User Account
        p
          span(style='font-weight: 400;')
            | Occasionally situations arise where we may need to limit, suspend, or terminate your account. Account limitation ("
          strong limit
          span(style='font-weight: 400;') ", "
          strong limitation
          span(style='font-weight: 400;')
            | ") results in a temporary or permanent loss of access to a certain specific part or parts of the services we offer. Account suspension ("
          strong suspension
          span(style='font-weight: 400;') ", "
          strong block
          span(style='font-weight: 400;') ", "
          strong ban
          span(style='font-weight: 400;')
            | ") results in a temporary loss of access to most parts of the services we offer. Account termination ("
          strong termination
          span(style='font-weight: 400;') ", "
          strong permanent ban
          span(style='font-weight: 400;')
            | ") results in permanent loss of access to all services we offer as well as termination of this Agreement.
        p
          span(style='font-weight: 400;')
            | We have the right to, limit, suspend, or terminate your account or access to the services that we provide to you if:
        ol
          li(style='font-weight: 400;')
            span(style='font-weight: 400;')
              | we have a reason to believe that you have acted in violation of these Terms of Service or applicable laws or regulations or if we have a reason to believe that any content or material submitted or shared by you in or through the services violates these Terms of Service or applicable laws or regulations or third party rights;
          li(style='font-weight: 400;')
            span(style='font-weight: 400;')
              | we suspect that you use the services to engage in, attempt to engage in, or in connection with fraudulent activity, money laundering, terrorist financing or other financial crime;
          li(style='font-weight: 400;')
            span(style='font-weight: 400;')
              | we have reason to believe that you have supplied false, misleading or deceptive information in connection with your registration, identity verification, trades or any other use of our services, either to us or to another user;
          li(style='font-weight: 400;')
            span(style='font-weight: 400;')
              | we are required to do so under any applicable law, regulation or an order issued by an authority;
          li(style='font-weight: 400;')
            span(style='font-weight: 400;')
              | make available content or behave in a way that libels, defames, invades privacy, stalks, is obscene, pornographic, racist, abusive, harassing, threatening or is offensive;
          li(style='font-weight: 400;')
            span(style='font-weight: 400;')
              | if you use the services in a manner which may cause the services to be interrupted or damaged or such a way that the functionality of the services is in any way impaired or attempt to bypass or break any security mechanism of the services or if Finex believes in good faith that such action is reasonably necessary to protect the security of its services or its property or brand or the security, property and rights of its users or those of third parties;
          li(style='font-weight: 400;')
            span(style='font-weight: 400;')
              | attempt any unauthorized access to user accounts or any part or component of the service.
          li(style='font-weight: 400;')
            span(style='font-weight: 400;')
              | It’s strictly prohibited to advertise directly and indirectly any third party services, that are not related to Finex.
        p
          span(style='font-weight: 400;')
            | We will normally give you a notice of any limitation, suspension, or termination of your account but we may, if it is reasonable to do so (for example we consider it advisable for security reasons), limit, suspend or terminate your account without prior notice to you.
        p
          span(style='font-weight: 400;')
            | You acknowledge that our decision to limit, suspend, or terminate your account, may be based on confidential criteria that are essential to our risk management and security protocols and that we are under no obligation to disclose the details of our risk management and security procedures to you.
        p
          span(style='font-weight: 400;')
            | In the event that we limit, suspend or terminate your account we will lift the suspension as soon as possible once the reasons for the suspension or termination no longer exist.
        h3
          strong Limiting Access to Your Finex Wallet
        p
          span(style='font-weight: 400;')
            | In certain situations your ability to access, conduct cryptocurrencies transactions, or start trades using cryptocurrencies held in your Finex wallet may be limited.
        p
          span(style='font-weight: 400;')
            | This limitation may be applied specifically to your account in order to safeguard cryptocurrencies stored in your wallet when we e.g. suspect a third party has gained unauthorized access to your account; if we suspect you of engaging in or being involved in fraudulent or illegal activity; if we are provided with valid legal request from an authority.
        p
          span(style='font-weight: 400;')
            | This limitation may be applied generally to all users or a subset of our users during service outages or maintenance outages of the website; during cryptocurrency hardforks or other situations where we believe there is a high risk to your cryptocurrencies balance.
        p
          span(style='font-weight: 400;')
            | In the event that we limit access to your wallet we aim to remove the limitation as soon as possible once the reasons for the suspension or termination no longer exist.
        p
          span(style='font-weight: 400;')
            | We will normally give you a notice of any limitation of access to your wallet but we may, if it is reasonable to do so (for example we consider it advisable for security reasons), limit access to your wallet without prior notice.
        p
          span(style='font-weight: 400;')
            | You acknowledge that our decision to limit access to your wallet may be based on confidential criteria that are essential to our risk management and security protocols and that we are under no obligation to disclose the details of our risk management and security procedures to you.
        h3
          strong Fees and Pricing
        p
          span(style='font-weight: 400;')
            | Any information related to actual fees and pricing is indicated on the following page <router-link :to="{name: 'Commissions'}" tag="a">Commissions</router-link>.
        p
          span(style='font-weight: 400;')
            | Please note, if fees and pricing are not indicated, the system will automatically charge a fee from the balance of your Finex wallet or deposit when buying or selling process is completed. You will be notified prior to any changes that our team wants to make.
        h3
          strong Supporting Blockchain Forks and Other Cryptocurrencies
        p
          span(style='font-weight: 400;')
            | From time to time, new and alternative cryptocurrencies and/or forks of the cryptocurrencies blockchain may be created. This may result in holders of private keys containing a cryptocurrency balance receiving a corresponding amount of the new cryptocurrency on the newly created blockchain (“air-drop”). We reserve the right at our sole discretion to decide if and how we support any new cryptocurrencies and/or blockchain forks in our services including defining which blockchain shall be deemed as cryptocurrency within the meaning of this Agreement. We strive to communicate our decisions concerning any significant new forks or cryptocurrencies on our website in a timely manner. If we decide to not support a new cryptocurrency we may, but are not obligated to, compensate users who held cryptocurrency in their Finex account at the time of the air-drop in a manner deemed appropriate by us. We reserve the right to provide compensation by converting all of the available new cryptocurrency to cryptocurrency and sharing all of the converted cryptocurrencies between users who held a cryptocurrencies balance on their account at the time of the creation of the new cryptocurrency. In case such a conversion takes place we may charge you a processing fee in any amount deemed reasonable by us at our sole discretion but not exceeding the amount of compensation payable to you.
        p
          span(style='font-weight: 400;')
            | If we decide to support a new cryptocurrency, unless otherwise communicated by us, these Terms of Service, including any reference to cryptocurrencies , shall be equally applied to the new cryptocurrency.
        p
          span(style='font-weight: 400;')
            | We cannot guarantee that the decisions we take under this Section will be suitable, desirable or practical to you. If you are concerned about our decisions, stances or the lack thereof regarding any given blockchain fork or new cryptocurrency, we recommend that you withdraw your balance and handle the situation on your own as you see fit. We are not in any circumstance liable for any damage, losses, expenses or harm resulting to you from the use of our rights under this Section.
        h3
          strong Allowed Jurisdictions
        p
          span(style='font-weight: 400;')
            | To use services provided by Finex you may need to fulfil certain legal obligations in your country and/or state of residence. By accepting these terms and service you confirm that you have reviewed your local laws and regulations and that you are aware of, and fulfill, any and all such obligations.
        p
          span(style='font-weight: 400;')
            | Finex does not offer the use of its services in certain jurisdictions, by accepting these terms of service you confirm that you are not a resident or governed by the laws and regulations of the Federal Republic of Germany as well as the States of New York and Washington in the United States of America.
        h3
          strong Intellectual Property Rights
        p
          span(style='font-weight: 400;')
            | You acknowledge and agree that all copyrights, trademarks and all other intellectual property rights in and related to this site and our services is exclusively the property of Finex and our licensors. We grant you a revocable, non-exclusive, non-sublicensable, non-transferable and limited license, subject to the terms of this Agreement, to access and use our site and service, as well as related content, materials and information (collectively, the "Content") solely for approved purposes as permitted by us from time to time. Any other use of the Content is expressly prohibited and you agree not to copy, transmit, distribute, sell, license, reverse engineer, modify, publish, or participate in the transfer or sale of, create derivative works from, or in any other way exploit any of the Content, in whole or in part.
        h3
          strong Disclaimer of Warranties and Limitation of Liability
        p
          span(style='font-weight: 400;')
            | This site and the services are provided on an “as is” and “as available” basis for your information and use without any representation or endorsement. To the maximum extent permitted by applicable law, we make no warranties of any kind, whether express or implied, in relation to the site or the services, including but not limited to, implied warranties of satisfactory quality, functionality, fitness for a particular purpose, non-infringement, compatibility, security, accuracy, condition or completeness, or any implied warranty arising from course of dealing or usage or trade.
        p
          span(style='font-weight: 400;')
            | Finex.market is not associated with or does not itself support or claim to be in partnership with any of the payment methods, services or companies which may appear visible in the Online Payment method lists or advertisement details. Also, services provided by Finex.market are not authorized, approved, endorsed or sponsored by any of the payment methods listed on the website or their respective trademark owners. Payment method listings are visible on Finex.market for informative purposes only.
        p
          span(style='font-weight: 400;')
            | Finex is not responsible for any user-generated content on its site including but not limited to messages, feedbacks or advertisements but may remove or modify said content without notice or liability at any time in its sole discretion.
        p
          span(style='font-weight: 400;')
            | This site may contain links to other sites on the Internet. These sites may contain information or material that some people may find inappropriate or offensive. These other sites are not under the control of Finex.market, and you acknowledge that (whether or not such sites are affiliated in any way with Finex.market) Finex.market is not responsible for the accuracy, legality, decency, or any other aspect of the content of such sites.
        p
          span(style='font-weight: 400;')
            | Finex.market reserves the right to modify or discontinue, temporarily or permanently, all or any part of this site and/or any software, facilities and services on this site, with or without notice, and/or to establish general guidelines and limitations on their use.
        p
          span(style='font-weight: 400;')
            | If and to the maximum extent permitted by applicable law, we will not be liable for:
        ol
          li(style='font-weight: 400;')
            span(style='font-weight: 400;')
              | any economic losses (including without limitation loss of revenues, profits, contracts, business or anticipated savings);
          li(style='font-weight: 400;')
            span(style='font-weight: 400;') any loss of goodwill or reputation;
          li(style='font-weight: 400;')
            span(style='font-weight: 400;') any special or indirect or consequential losses, howsoever arising
        p
          span(style='font-weight: 400;')
            | in any case whether or not such losses were within the contemplation of either of us at the date on which the event giving rise to the loss occurred. Without limitation, you (and not us) assume the entire cost of all necessary servicing, repair or correction or correction in the event of any such loss or damage arising.
        p
          span(style='font-weight: 400;')
            | Nothing in these Terms of Service shall exclude or limit our liability based on willful conduct or gross negligence. If and to the extent applicable law does not allow the above limitations of warranty and liability, such limitations shall not apply to you.
        p
          span(style='font-weight: 400;')
            | Notwithstanding any other provision in these Terms of Service, nothing shall limit your rights as a consumer under mandatory provisions of applicable consumer protection legislation.
        h3
          strong Indemnity
        p
          span(style='font-weight: 400;')
            | You agree to be fully responsible for (and fully indemnify us against) all claims, liability, damages, losses, costs and expenses, including legal fees, suffered by us and arising out of or related to any breach of this Agreement by you or any other liabilities incurred by us arising out of your use of the services, or use by any other person accessing the services using your user account, device or internet access account; or your violation of any law or rights of any third party.
        h3
          strong General
        p
          span(style='font-weight: 400;')
            | We may transfer or assign our rights and duties under this Agreement to any party at any time without notice to you, but this will not affect your rights or our obligations under this Agreement. You may not, however, transfer or assign any of your rights and duties under this Agreement to any other party.
        p
          span(style='font-weight: 400;')
            | This Agreement (as amended from time to time) contains the entire agreement and understanding between us in respect of all matters which are referred to herein and supersedes any prior written or oral agreement between us relating to such matters. No oral explanation or oral information given by either of us shall alter the interpretation of this Agreement. You confirm that, in agreeing to accept this Agreement, you have not relied on any representation that is not expressly included herein and you agree that you shall have no remedy in respect of any misrepresentation which has not become a term of this Agreement. However, nothing in this Agreement purports to exclude liability for any fraudulent statement or act.
        p
          span(style='font-weight: 400;')
            | If any part of provision this Agreement is deemed unlawful, void or for any reason unenforceable, then that provision shall be deemed to be severable from this Agreement and shall not affect the validity and enforceability of any of the remaining provisions of the Agreement.
        p
          span(style='font-weight: 400;')
            | Our failure to enforce any right or provision in this Agreement will not constitute a waiver of such right or provision unless acknowledged and agreed to by us in writing. Except as expressly set forth in this Agreement, the exercise by either party of any of its remedies under this Agreement will be without prejudice to its other remedies under this Agreement or otherwise permitted under law.
        p
          span(style='font-weight: 400;')
            | You agree that this Agreement and any dispute between you and us shall be governed in all respects by the laws of Hong Kong, without regard to their choice-of-law provisions, and excluding the application of the 1980 U.N. Convention on Contracts for the International Sale of Goods. Except if prohibited and without limitation to any statutory rights for consumers under applicable consumer protection laws, you agree that all disputes, claims and proceedings arising out of or relating to the services shall be resolved by the competent courts of Hong Kong. However, we shall always have the right to take legal proceedings in the court of competent jurisdiction of your domicile. All claims shall be brought within one (1) year after the claim arises. Failure of either party to exercise in any respect any right under this Agreement shall not be deemed to be a waiver of such right.
        p
          span(style='font-weight: 400;')
            | The services are controlled and offered by us from Hong Kong. We make no representations that the services are appropriate or available for use in other countries. Users of Finex.market are themselves responsible for making sure they are in compliance with legislation of the jurisdiction they operate and reside in.
        p
          strong Contact Information
        p
          span(style='font-weight: 400;') Site:&nbsp;
          a(href='https://finex.market/ ')
            span(style='font-weight: 400;') https://finex.market/
        p
          span(style='font-weight: 400;') Email:&nbsp;
          a(href='mailto:support@finex.market')
            span(style='font-weight: 400;') support@finex.market
        p
          span(style='font-weight: 400;') Mail for government agencies:&nbsp;
          a(href='mailto:lawyer@finex.market')
            span(style='font-weight: 400;') lawyer@finex.market
</template>


<script>
export default {
  name: 'TermsOfUse',
};
</script>

<style lang="scss" scoped>
  .page-title {
    font-size: 35px;
  }

  .page-text {
    font-size: 18px;
  }

  .terms_of_use {
    h1 {
      display: block;
      font-size: 2em;
      margin-block-start: 0.67em;
      margin-block-end: 0.67em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
    }
    h3 {
      display: block;
      font-size: 1.17em;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
    }
    p {
      line-height: 20px;
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
    }
    strong {
      font-weight: bold;
    }
    ol {
      display: block;
      list-style-type: decimal;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
    }
    li {
      display: list-item;
      text-align: -webkit-match-parent;
    }
    ul {
      display: block;
      list-style-type: disc;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
    }
  }
</style>
